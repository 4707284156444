const WalkThroughData = [
  {
    id: 1,
    cover: require("../../Assets/Walkthrough/gallery1.jpg"),
    alttag: "DLF The Dahlias",
  },
  {
    id: 2,
    cover: require("../../Assets/Walkthrough/gallery2.jpg"),
    alttag: "DLF The Dahlias",
  },

];

export default WalkThroughData;
